@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: black;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 6px;
  right: 4px;
  width: 32px;
  height: 32px;
  font: 24px/24px Tahoma, Verdana, sans-serif;
}

.leaflet-marker-icon:hover {
  border: 1px solid black;
  transition: 1s all;
}
